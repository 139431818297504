import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'





function Parking () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [parking, setParking] = useState([])
      const navigate = useNavigate()
  
      const parkingCollectionRef = collection(db, "Parking");
      useEffect(() => {
  
          const getParking = async () => {
              const data = await getDocs(parkingCollectionRef);
              setParking(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getParking()
      }, [])
    return (
      
<>




<br/>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
                <center><h2 style={{color:"darkBlue"}}><b>Our Parking Area</b></h2></center>
                </div>
                </div>
            </div>

<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {parking.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.img.toLowerCase().includes(searchTerm.toLowerCase()) 
       
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((basashreeinn) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={basashreeinn.img}  alt="parking-area"/>
                       
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={bala.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Parking;



