import React from 'react'
import Banquethall from "../images/banquethall.jpg";
import Banquetside from "../images/banquet-side.jpg";

function Banquet() {
  return (
    <div>
            <div className='album py-3'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-6 mt-2 ">
                        <div className="card border-secondary mb-3" >
  <div className="card-header"><center>BANQUET HALL</center></div>
  <div className="card-body text-secondary">
  <img src={Banquethall} className="card-img-top mb-2" alt="Banquet"/>

    <h5 className="card-title mb-1">Banquet Hall 1</h5>
    <p className="card-text">Best Location Banquet Hall in Krishnagar, Clean & Safe Are </p>
  </div>
</div>
                        </div>
                        <div className="col-md-6 mt-2 ">
                        <div className="card border-secondary mb-3" >
                        <div className="card-header"><center>BANQUET HALL</center></div>
  <div className="card-body text-secondary">
  <img src={Banquetside} className="card-img-top mb-2" alt="Banquet"/>
  <h5 className="card-title mb-1">Banquet Hall 1</h5>
    <p className="card-text">Best Location Banquet Hall in Krishnagar, Clean & Safe Are </p>
  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Banquet