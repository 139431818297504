import React from 'react'
import Banquethall from "../images/banquet.jpg";
import Roomtype from "../images/room type.jpg"

function Slider() {
    return (
        <div>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={Banquethall} className="d-block w-100" alt="Banquethall" />
                        {/* <div className="carousel-caption d-none d-md-block">
                        <div className="album py-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-5 mt-0">
                                            <div className="card" >
                                                <div className="card-body">
                                                    <h6 className="card-title"> <small>Storii By BASASHREE INN Hotel | Krishnagar, Highstreet</small></h6>
                                                    <hr/>
                                                    <p className="card-text">Exclusive Introductory, Hotel Credti, Breakfast Complemntory More</p>
                                                    <button className='buttonhome'> KNOW MORE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="carousel-item">
                        <img src={Roomtype} className="d-block w-100" alt="Roomtype" />
                        {/* <div className="carousel-caption d-none d-md-block">
                            <h5>Second slide label</h5>
                            <p>Some representative placeholder content for the second slide.</p>
                        </div> */}
                    </div>
                    <div className="carousel-item">
                        <img src={Banquethall} className="d-block w-100" alt="..." />
                       
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Slider