
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Deluxeroom from './components/pages/Deluxeroom';
import Standard from './components/pages/Standard';
import Contacts from './components/pages/Contacts';
import Banquethall from './components/pages/Banquethall';
import Payment from './components/pages/Payment';
function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Deluxeroom" element={<Deluxeroom/>}/>
      <Route axact path="/standard-room" element={<Standard/>}/>
      <Route axact path="/banquethall" element={<Banquethall/>}/>
      <Route axact path="/Contacts" element={<Contacts/>}/>
      <Route axact path="/Payment" element={<Payment/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
