import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";


import Banquet from './Banquet';
function Banquethall() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <div className='contactback'>
                <div className="album py-1  ">
                    <div className="container mt-3">
                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>

                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        
            <Banquet/>

        </div>
    )
}

export default Banquethall