
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyD1PzvrCinRhPGYKuzMGY6KcALEpC-Z_vQ",
    authDomain: "basashree-inn.firebaseapp.com",
    projectId: "basashree-inn",
    storageBucket: "basashree-inn.appspot.com",
    messagingSenderId: "442466997818",
    appId: "1:442466997818:web:30fe797e3fe42148dd0703",
    measurementId: "G-3R4Z9LDY2J"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;