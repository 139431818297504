import React from 'react'
import Navbar from '../inc/Navbar'
import pay from "../images/phone pe.jpeg";
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Payment() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br/>
    
      <ScrollToTop smooth top="100" color="darkBlue" />
      <div className='contactback'>
        <div className="album py-1  ">
          <div className="container mt-3">
            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Payment</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className='album py-3'>
        <div className='container'>
          <div className='row'>
            <center>
            <div className="col-md-6 mt-2">
              <div className="card" >

                <div className="card-body">
                  <h5 className="card-title mb-3"><center>Payment</center></h5>
                  <hr/>
                  <img src={pay} className="card-img-top" alt="..." />
                
                </div>
              </div>
            </div>
            </center>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Payment