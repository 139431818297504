import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import Deluxe from '../inc/Deluxe';
import ScrollToTop from "react-scroll-to-top";
import Standard from '../inc/Standard';
import Reception from "../images/reception.jpg";
import Gallery from './Gallery';
import Banquet from './Banquet';
import Mapgoogle from './Mapgoogle';

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <Slider />
      <br />
      <ScrollToTop smooth top="100" color="darkBlue" />
      <center><h4>WELCOME TO BASASHREE INN HOTELS & RESORTS</h4></center>
      <div className='backcolor'>
        <div className='album py-0'>
          <div className='container'>
            <div className='row'>
              <div className="d-flex justify-content-between align-items-center mb-0 mt-3">
                <p style={{ color: "white" }} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                </svg> Best Price</p>
                <p style={{ color: "white" }} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg> Exclusive Location </p>
                <a href='tel: 9046120299'>
                  <p style={{ color: "white" }}><smaLL><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z" />
                  </svg> Call Book</smaLL></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='container'>
        <div className='row'>
          <center><h2 style={{ color: "grey" }}>EXPLORE ROOM</h2>
            <p>Best hotel in krishnagar, Our hotel started after independence of the country, rooms and banquet hall available, roof top kitchen facility, seating for 1000 people</p></center>
        </div>
      </div>
      <div className='album py-3'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-6 mt-2 ">
              <div className="card shadow" >
                <Deluxe />
                <div className="card-body">
                  <h5 className="card-title mb-1">Deluxe Room </h5>
                  <p className="card-text mb-3"><small>Double & Single Bed, Clean Room, Best Location</small></p>
                  <h5 style={{ color: "darkBlue" }}>Price: Double- 2200/-</h5>
                  <h5 style={{ color: "darkBlue" }} className='mb-3'>Price: Single- 1800/-</h5>
                  <div style={{ color: "maroon" }} className="d-flex justify-content-between align-items-center mb-0 ">
                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg> Check in - 12:00 PM</p>
                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>  Check out - 10:00 AM</p>
                  </div>
                  <div style={{ color: "maroon" }} className="d-flex justify-content-between align-items-center mb-0 ">
                    <a href="tel: 9046120299" className="buttoncolor">BOOK A STAY</a>
                    <a href="Deluxeroom" className="buttoncolorVIEW">VIEW DETAILS</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <div className="card shadow" >
                <Standard />
                <div className="card-body">
                  <h4 className="card-title mb-1">Standard Room </h4>
                  <p className="card-text mb-3"><small>Double & Single Bed, Clean Room, Best Location</small></p>
                  <h5 style={{ color: "darkBlue" }} className='mb-1'>Price: Double Bed- 1500/-</h5>
                  <h5 style={{ color: "darkBlue" }} className='mb-1'>Price: Single- 1200/-</h5>
                  <h5 style={{ color: "darkBlue" }} className='mb-1'>Price: Tripple- 1800/-</h5>
                  <h5 style={{ color: "darkBlue" }} className='mb-3'>Price: Driver- 300/-</h5>
                  <div style={{ color: "maroon" }} className="d-flex justify-content-between align-items-center mb-0 ">
                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg> Check in - 12:00 PM</p>
                    <p> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>  Check out - 10:00 AM</p>
                  </div>
                  <div style={{ color: "maroon" }} className="d-flex justify-content-between align-items-center mb-0 ">
                    <a href="tel: 9046120299" className="buttoncolor">BOOK A STAY</a>
                    <a href="standard-room" className="buttoncolorVIEW">VIEW DETAILS</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br/>
      <div className='album py-1'>
        <div className='container'>
          <div className="row">
            <div className="col-md-6 mt-2" >

              <img src={Reception} className="card-img-top mb-3" alt="Reception" />


            </div>
            <div className="col-md-6 mt-2" >


              <div className="card-body">
                <h4 className="card-title mb-2">BASASHREE INN</h4>
                <h6 className="card-title mb-3">Reception</h6>
                <p className="card-text">Krishnagar
69 R.N Tagore Road, High Street, 741101, West Bengal, India | Word No:20, Jagadhatri Temple Bisharjan Road</p>
                <a href="tel: 9046120299" className="buttoncolor mb-2"> +91 9046120299</a>
                <p><small>Call Time: 07:00AM to 11:00PM</small></p>
              </div>

            </div>
          </div>
        </div>
      </div>

<br/>
<Banquet/>
<br/>
<Gallery/>
<br/>
<Mapgoogle/>
<br/>
    </div>
  )
}

export default Home