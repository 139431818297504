import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import Deluxepage from "../images/STANDARROON-BANNAR.jpg";
import standardrooms from "../images/standar-room.jpg";
import roomnormal from "../images/basashreeinn-room.jpg"
import standardroom5 from "../images/Standard-room5.jpg"
import standardroom6 from "../images/standard-room 6.jpg"
import Doublebedroom from "../images/doublebedroom.jpg"
import room from "../images/room.jpg"

import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";


import Parking from './Parking';
import Standardbathroom from '../inc/Standardbathroom';


function Standard() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <ScrollToTop smooth top="100" color="darkBlue" />
            <img src={Deluxepage} className="d-block w-100" alt="..." />
            <br />
            <div className='album py-0'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6 mt-2" >


                            <div className="card-body">
                                <h3 className="card-title mb-3">Basashree Inn Krishnagar</h3>
                                <a href='https://maps.app.goo.gl/kBkJQsVxEirUqSAb8' target="_blank" rel="noopener noreferrer">
                                    <p style={{ color: "darkgoldenrod" }} className="card-text"><small> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg> 69 R.N Tagore Road, High Street, Krishnagar</small></p>
                                    <a style={{ color: "grey" }} href="tel: +91 9046120299" className="mb-3">
                                        <p>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                        </svg> +91 9046120299</p>
                                    </a>
                                </a>

                                <p className='mb-3'><small>Best Hotel in Krisshnagar, Our hotel started after the independence of the country, Room & Banquet Hall Available, Roof Top Kitchen Facility, 1000 People Seting</small> </p>
                                <div className="d-flex justify-content-between align-items-center mb-0 ">
                                    <h5><small> Standard Double Bed Room (A/C) Tariff - <b> 1,200/-</b></small></h5>
                                    <h5><small> Single Occupancy (A/C) Tariff- <b>1,000/-</b></small></h5>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-0 ">
                                    <h5><small> Tripple Bed (A/C) - 1,500/-</small></h5>
                                    <h5><small>Extra Bed Foe All Room- 300/-</small></h5>
                                </div>
                                <button type="button" className="buttoncolorV" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                                    </svg>
                                </button>

                                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">

                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <center>
                                                    < FacebookShareButton url="https://www.basashreeinn.com/stanadard-room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                                                    >
                                                        <FacebookIcon size={37} round={true} />
                                                    </FacebookShareButton>

                                                    <  WhatsappShareButton url="https://www.basashreeinn.com/stanadard-room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                                                    >
                                                        <WhatsappIcon size={37} round={true} />
                                                    </ WhatsappShareButton>

                                                    <  TwitterShareButton url="https://www.basashreeinn.com/stanadard-room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                                                    >
                                                        <TwitterIcon size={37} round={true} />
                                                    </ TwitterShareButton>

                                                    <  LinkedinShareButton url="https://www.basashreeinn.com/stanadard-room" className="me-2" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                                                    >
                                                        <  LinkedinIcon size={37} round={true} />
                                                    </ LinkedinShareButton>

                                                    <  EmailShareButton url="https://www.basashreeinn.com/stanadard-room" quote={"Ps Luxury Hotel"} hastag={"#WebApp"}
                                                    >
                                                        <  EmailIcon size={37} round={true} />
                                                    </ EmailShareButton>
                                                </center>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <h4>Hotel Highlights</h4>
                                <div className="d-flex justify-content-between align-items-center mb-0 ">
                                    <p><small>Located in Krishnagar NADIA District</small></p>
                                    <p><small>Parking Facility</small></p>
                                    <p><small>Breakfast Complementory</small></p>


                                </div>
                                <br />

                                <h5>Hotel Policies</h5>
                                <p className='mb-0'><small><span>Check-in time: 12:00 PM</span></small></p>
                                <p className='mb-0'><small><span>Check-out time: 12:00 AM NOON</span></small></p>
                                <p className='mb-0'><small><span>Early check-in and late check-out on request</span></small></p>
                                <p><small><span>Hookah, Speakers, Outside food and Candles are not allowed.</span></small></p>


                            </div>
                        </div>

                        <div className="col-md-3 mt-2" >
                            <img src={standardrooms} className="card-img-top mb-2" alt="krishnagar_room" />
                            <img src={roomnormal} className="card-img-top mb-2" alt="basashreein" />

                            <img src={standardroom5} className="card-img-top mb-2" alt="krishnagar_room" />
                        </div>
                        <div className="col-md-3 mt-2" >
                            <img src={room} className="card-img-top mb-2" alt="krishnagar_room" />


                            <img src={Doublebedroom} className="card-img-top mb-2" alt="basashreein" />
                            <img src={standardroom6} className="card-img-top mb-2" alt="krishnagar_room" />
                            <center>
                                <button type="button" className="bathroombutton" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> <small>VIEW BATHROOM PHOTOS (4)</small>
                                </button>

                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel"></h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <Standardbathroom />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="buttonhome" data-bs-dismiss="modal">Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Parking />
            <br />
            <br />
        </div>
    )
}

export default Standard