import React from 'react'
import Deluxeroompage from "../images/roomdeluxe.jpg"
import Deluxeroom from "../images/basashreeinnroom.jpg"
import Basashree from "../images/basashreeinn-room.jpg"
import Deluxebath from '../inc/Deluxbath'
function Deluxephoto() {
    return (
        <div>
           
                <div className='album py-0'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 mt-2">
                                <img src={Deluxeroompage} className="card-img-top mb-2" alt="basashreeinn" />

                                <img src={Deluxeroom} className="card-img-top mb-2" alt="basashreeinn" />


                            </div>
                            <div className="col-md-6 mt-2">
                                <img src={Basashree} className="card-img-top mb-2" alt="basashreeinn" />

                                <img src={Deluxeroompage} className="card-img-top mb-2" alt="basashreeinn" />

                                <center>
                               
<button type="button" className="bathroombutton" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  <small> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                    </svg> VIEW BATHROOM PHOTOS (4)</small>
</button>

<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
      
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Deluxebath/>
      </div>
      <div className="modal-footer">
        <button type="button" className="buttonhome" data-bs-dismiss="modal">Close</button>
   
      </div>
    </div>
  </div>
</div>
                            </center>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


       
    )
}

export default Deluxephoto