import React from 'react'

function Mapgoogle() {
    return (
        <div>
            <div className="album py-0">

                <div className="container">

                    <div className="row">

                        <iframe className="iframe-fluid mb-3" height="380" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=BASASHREE%20Inn,%20High%20Street,%20RN%20Tagore%20Rd,%20Krishnanagar,%20West%20Bengal%20741101%20+(BASASHREE%20Inn,%20High%20Street,%20RN%20Tagore%20Rd,%20Krishnanagar,%20West%20Bengal%20741101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        <iframe className="iframe-fluid" src="https://www.google.com/maps/embed?pb=!4v1711373257711!6m8!1m7!1sKLSVLMhelvuOJnbzS6bgkA!2m2!1d23.41203885131335!2d88.49444374570702!3f125.297521030756!4f-7.596595474731473!5f0.4000000000000002" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mapgoogle